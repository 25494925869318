import React from "react";

const Gleekenomics = () => {
	return (
		<div className="gleekenomics-container">
			<img
				src="./images/logos/gleekenomics.png"
				alt=""
				className="gleekenomics-container-img"
			/>
		</div>
	);
};

export default Gleekenomics;
